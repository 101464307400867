import React, { useState, useCallback, useEffect } from 'react';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Button,
  IconButton,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Chip,
  Avatar,
  Tooltip,
  Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InfoIcon from '@mui/icons-material/Info';
import ChatIcon from '@mui/icons-material/Chat';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import MensagensModal from './MensagensModal'; // Importe o MensagensModal

const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: '20px',
  backgroundColor: '#fff',
  color: '#333',
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 8px 30px rgba(0,0,0,0.15)',
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1.5),
  },
}));

const LeadAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: '#1976d2',
  width: theme.spacing(7),
  height: theme.spacing(7),
  fontSize: '2rem',
  [theme.breakpoints.down('sm')]: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    fontSize: '1.5rem',
  },
}));

function LeadCard({ lead, onViewDetails }) {
  const [status, setStatus] = useState(lead.status || '');
  const [showAdditionalPhones, setShowAdditionalPhones] = useState(false);
  const [openMessagesModal, setOpenMessagesModal] = useState(false);
  const [openPdfModal, setOpenPdfModal] = useState(false);
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState(null);

  const toggleAdditionalPhones = () => {
    setShowAdditionalPhones(!showAdditionalPhones);
  };
  
  const [pdfLoading, setPdfLoading] = useState(false);
  const [pdfDataUrl, setPdfDataUrl] = useState(null);
  const pdfContainerRef = React.useRef(null);
  
  const handleViewProcesso = () => {
    if (!lead.processo) return;
    
    console.log(`Iniciando visualização do processo: ${lead.processo}`);
    
    // Abre o modal do PDF
    setOpenPdfModal(true);
    setPdfLoading(true);
    
    // Limpar o container primeiro se já existir
    if (pdfContainerRef.current) {
      pdfContainerRef.current.innerHTML = '';
    }
    
    // URL para o servidor proxy
    const proxyUrl = `https://pdfd.wh.tec.br/api/pdf/view/${lead.processo}`;
    console.log(`Enviando requisição para: ${proxyUrl}`);
    
    // Criar iframe para exibir o PDF
    setTimeout(() => {
      if (pdfContainerRef.current) {
        const iframe = document.createElement('iframe');
        iframe.src = proxyUrl;
        iframe.style.width = '100%';
        iframe.style.height = '100%';
        iframe.style.border = 'none';
        
        // Eventos de carregamento
        iframe.onload = () => {
          console.log('PDF carregado com sucesso!');
          setPdfLoading(false);
        };
        
        iframe.onerror = () => {
          console.error('Erro ao carregar o PDF');
          setPdfLoading(false);
          
          if (pdfContainerRef.current) {
            pdfContainerRef.current.innerHTML = `
              <div style="display: flex; justify-content: center; align-items: center; height: 100%; color: red;">
                Erro ao carregar o PDF. Por favor, tente novamente.
              </div>
            `;
          }
        };
        
        // Adicionar ao container
        pdfContainerRef.current.appendChild(iframe);
      }
    }, 500); // Pequeno delay para garantir que o modal esteja aberto
  };
  
  const handleClosePdfModal = () => {
    setOpenPdfModal(false);
    setPdfLoading(false);
    
    // Revogar o URL de dados quando o modal for fechado
    if (pdfDataUrl) {
      URL.revokeObjectURL(pdfDataUrl);
      setPdfDataUrl(null);
    }
    
    // Limpar conteúdo do container PDF
    if (pdfContainerRef.current) {
      pdfContainerRef.current.innerHTML = '';
    }
  };
  
  // Timeout de segurança para o indicador de carregamento
  useEffect(() => {
    if (openPdfModal && pdfLoading) {
      // Definir um timeout para ocultar o indicador de carregamento caso demore muito
      const timer = setTimeout(() => {
        console.log('Timeout de carregamento atingido');
        setPdfLoading(false);
      }, 20000);
      
      return () => {
        clearTimeout(timer);
      };
    }
  }, [openPdfModal, pdfLoading]);

  const fetchMensagens = useCallback(async (phone) => {
    const token = localStorage.getItem('token');
    const consultorName = localStorage.getItem('username');

    try {
      const response = await axios.get(`https://msg.wh.tec.br/mensagens/55${phone}`, {
        headers: {
          Authorization: token,
          'consultor-name': consultorName,
        },
      });

      return response.data.mensagens.map((msg) => ({
        ...msg,
        enviadaPeloUsuario: msg.enviadaPeloUsuario === 'Sim',
        timestamp: msg.timestamp,
      }));
    } catch (error) {
      console.error('Erro ao buscar mensagens:', error);
      return [];
    }
  }, []);

  const handleViewMessages = async (phone) => {
    setSelectedPhoneNumber(phone);
    setOpenMessagesModal(true);
  };

  // Função para atualizar o status no estado local e no backend
  const handleStatusChange = async (e) => {
    const newStatus = e.target.value;
    setStatus(newStatus); // Atualiza o estado local

    const token = localStorage.getItem('token');

    try {
      const response = await fetch('https://apil2.wh.tec.br/base_sp/status', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify({
          leadId: lead.id, // Certifique-se que o lead possui a propriedade id
          status: newStatus,
        }),
      });

      if (!response.ok) {
        throw new Error('Erro ao atualizar o status');
      }

      // Se necessário, trate a resposta da API aqui
    } catch (error) {
      console.error('Erro ao atualizar o status:', error);
      // Opcional: reverta o estado ou exiba uma mensagem de erro para o usuário
    }
  };

  // Extrai os telefones adicionais
  const additionalPhones = [
    lead.telefone_adicional2,
    lead.telefone_adicional3,
    lead.telefone_adicional4,
    lead.telefone_adicional5,
  ].filter(Boolean); // Remove valores falsy

  return (
    <StyledCard>
      <CardContent>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={3} sm={2}>
            <LeadAvatar>{lead.nome_cliente.charAt(0).toUpperCase()}</LeadAvatar>
          </Grid>
          <Grid item xs={9} sm={10}>
            <Typography variant="h6" noWrap sx={{ fontWeight: 'bold', color: '#1976d2' }}>
              {lead.nome_cliente}
            </Typography>
            <Typography variant="body2" color="textSecondary" noWrap>
              Processo: {lead.processo}
            </Typography>
            <Box sx={{ mt: 1 }}>
              <Chip
                label={lead.status || 'Sem Status'}
                color={
                  lead.status === 'enviado'
                    ? 'primary'
                    : lead.status === 'negociando'
                    ? 'warning'
                    : lead.status === 'nao responde'
                    ? 'error'
                    : lead.status === 'venda realizada'
                    ? 'success'
                    : 'default'
                }
                size="small"
              />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant="body2" sx={{ fontWeight: 600 }}>
              Celular: {lead.telefone_principal || 'N/A'}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Tooltip title="Enviar mensagem via WhatsApp">
              <IconButton size="small" color="success">
                <WhatsAppIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Ver mensagens">
              <IconButton
                size="small"
                onClick={() => handleViewMessages(lead.telefone_principal)}
                sx={{
                  ml: 1,
                  color: '#fff',
                  backgroundColor: '#1976d2',
                  '&:hover': {
                    backgroundColor: '#115293',
                  },
                }}
              >
                <ChatIcon />
              </IconButton>
            </Tooltip>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth size="small">
              <InputLabel>Status</InputLabel>
              <Select value={status} onChange={handleStatusChange} label="Status">
                <MenuItem value="">
                  <em>Selecione</em>
                </MenuItem>
                <MenuItem value="enviado">Enviado</MenuItem>
                <MenuItem value="negociando">Negociando</MenuItem>
                <MenuItem value="nao responde">Não Responde</MenuItem>
                <MenuItem value="venda realizada">Venda Realizada</MenuItem>
                <MenuItem value="resolveu com o banco">Resolveu com o banco</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              variant="contained"
              size="small"
              startIcon={<PictureAsPdfIcon />}
              onClick={handleViewProcesso}
              disabled={!lead.processo}
              sx={{
                backgroundColor: '#d32f2f',
                '&:hover': {
                  backgroundColor: '#b71c1c',
                },
              }}
            >
              Ver Processo
            </Button>
          </Grid>

          <Grid item xs={12}>
            <Link
              onClick={toggleAdditionalPhones}
              sx={{
                cursor: 'pointer',
                color: '#1976d2',
                fontWeight: 'bold',
                textDecoration: 'none',
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              {showAdditionalPhones ? 'Ocultar Telefones' : 'Ver Mais Telefones'}
            </Link>
          </Grid>

          {/* Renderiza os telefones adicionais se showAdditionalPhones for true */}
          {showAdditionalPhones && additionalPhones.length > 0 && (
            <>
              {additionalPhones.map((phone, index) => (
                <Grid container spacing={2} alignItems="center" key={index} sx={{ mt: 1 }}>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2" sx={{ fontWeight: 600 }}>
                      Telefone Adicional {index + 2}: {phone}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Tooltip title="Enviar mensagem via WhatsApp">
                      <IconButton size="small" color="success">
                        <WhatsAppIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Ver mensagens">
                      <IconButton
                        size="small"
                        onClick={() => handleViewMessages(phone)}
                        sx={{
                          ml: 1,
                          color: '#fff',
                          backgroundColor: '#1976d2',
                          '&:hover': {
                            backgroundColor: '#115293',
                          },
                        }}
                      >
                        <ChatIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </Grid>
              ))}
            </>
          )}
        </Grid>


        <MensagensModal
          open={openMessagesModal}
          onClose={() => setOpenMessagesModal(false)}
          selectedPhoneNumber={selectedPhoneNumber}
          fetchMensagens={fetchMensagens}
        />

        {/* Modal do PDF */}
        <Dialog
          open={openPdfModal}
          onClose={handleClosePdfModal}
          fullScreen
          PaperProps={{
            sx: { 
              overflow: 'hidden'
            }
          }}
        >
          <DialogTitle sx={{ m: 0, p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6">Processo: {lead.processo}</Typography>
            <IconButton
              onClick={handleClosePdfModal}
              aria-label="close"
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers sx={{ p: 0, height: '100%', overflow: 'hidden' }}>
            {lead.processo && openPdfModal && (
              <>
                {pdfLoading && (
                  <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center',
                    height: '100%',
                    position: 'absolute',
                    width: '100%',
                    backgroundColor: 'rgba(255,255,255,0.7)',
                    zIndex: 10
                  }}>
                    <Typography>Carregando PDF...</Typography>
                  </Box>
                )}
                <Box 
                  ref={pdfContainerRef} 
                  sx={{ 
                    width: '100%', 
                    height: '100%', 
                    '& .pdfobject': { 
                      height: '100% !important', 
                      border: 'none' 
                    }
                  }}
                />
              </>
            )}
          </DialogContent>
        </Dialog>
      </CardContent>
    </StyledCard>
  );
}

export default LeadCard;
